body,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
th,
td,
input,
button {
    padding: 0;
}
ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}
body {
    font-size: 12px;
    font-family: simsun, arial, helvetica, clean, sans-serif;
    color: #2b2b2b;
}
h1,
h2 {
    font-size: 14px;
}
h3,
h4 {
    font-size: 12px;
}
h2,
h4 {
    font-weight: normal;
}
h5,
h6 {
    font-size: 100%;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    & img {
        border: none\9;
    }
}
:global {
    & .clearfix {
        *zoom: 1;
    }
    & .clearfix:after {
        content: '\200B';
        display: block;
        height: 0;
        clear: both;
    }
}
