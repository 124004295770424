.lunbo {
    margin-bottom: 10px;
    overflow: hidden;
    & [ifeng_ui_pc_sliders-dots] div {
        margin: 0 4px;
        width: 5px;
        height: 5px;
    }
    & .dotCurrent {
        background-color: #ff2f42;
    }
    & [ifeng_ui_pc_sliders-dots] {
        bottom: 60px;
    }
}
.box,
.image {
    height: 360px;
}
.image {
    width: 640px;
}
.link {
    height: 42px;
    font: 20px '微软雅黑';
    line-height: 42px;
    color: #fff;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}
