.piaohong {
    position: relative;
    margin: 0 auto;
    width: 1000px;
}
.piaohonglink {
    display: block;
    width: 100%;
    height: 100%;
}
.close {
    position: absolute;
    top: 15px;
    right: 0;
    display: block;
    width: 36px;
    height: 12px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}
:global(.yuandan) {
    background-repeat: no-repeat;
    background-position: top center;
}
