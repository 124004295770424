.news_list {
    font-size: 14px;
    & a {
        color: #000;
        &:hover {
            color: #ff2f42; /* 变量 */
        }
    }
}
.has_image {
    padding: 10px 0;
}
.image_link {
    float: left;
    display: block;
    position: relative;
    margin-right: 15px;
    width: 98px;
    height: 54px;
    overflow: hidden;
    & img {
        width: 100%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    &:hover {
        & .play {
            background-image: url('./playHover.png');
        }
        & img {
            -webkit-transform: scale(1.04, 1.04);
            transform: scale(1.04, 1.04);
        }
    }
}
.play {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px;
    background-image: url('./play.png');
}
.image_title {
    float: left;
    width: 185px;
    height: 44px;
    line-height: 24px;
    font-weight: normal;
    overflow: hidden;
    font-size: 14px;
    & a {
        color: #000;
    }
}
.no_image {
    margin-bottom: 10px;
    padding-left: 10px;
    max-height: 50px;
    line-height: 25px;
    overflow: hidden;
    background: url('./dian.png') no-repeat left 10px;
    color: #ccc;
}
.ad {
    position: absolute;
    top: 13px;
    right: 60px;
}

.firstImageBox {
    padding: 10px 0;
}

.firstImage {
    display: block;
    width: 100%;
}
.bigImageNew {
    width: 301px;
    height: 182px;
    position: relative;
    margin: 10px 0;
}
.bigImageNewText {
    position: absolute;
    width: 267px;
    height: 36px;
    padding: 0 17px;
    line-height: 36px;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    bottom: 0;
    font-size: 14px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
