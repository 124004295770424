.logo_box {
    position: relative;
    margin: 10px auto;
    width: 1000px;
}
.logoBox2 {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

.logoBox2,
.logo_text,
.logo_image,
.logo_second_text {
    *float: left;
}

.logo_finace {
    height: 27px;
}
.slogen {
    margin-left: 10px;
    height: 25px;
}

.logo_text {
    margin: 5px 0 0 10px;
    font-size: 26px;
    vertical-align: 3px;
}
.logo_image {
    margin: 5px 0 0 10px;
    height: 27px;
}
.logo_second_text {
    margin-left: 5px;
    font-size: 14px;
    vertical-align: 4px;
    *position: relative;
    *top: 14px;
}
.logo_gt {
    margin-right: 5px;
}
.ad {
    position: absolute;
    left: 370px;
    bottom: 2px;
    width: 120px;
    height: 25px;
}
