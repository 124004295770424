.nav_box {
    margin-bottom: 30px;
    background-color: #f54343; /* 变量 */
    height: 38px;
}
.nav,
.nav li {
    background-image: url('./shu.png');
    background-repeat: repeat-y;
}
.nav {
    margin: 0 auto;
    padding-left: 2px;
    width: 1000px;
    height: 100%;
    font: 14px '微软雅黑';
    background-position: left top;
    & li {
        float: left;
        padding: 0 19px 0 17px;
        line-height: 38px;
        background-position: right top;
    }
    & a {
        color: #fff; /* 变量 */
        &:hover {
            color: #ffc201; /* 变量 */
            text-decoration: none;
        }
    }
}

.nav_piaohong {
    margin: 0 auto 30px;
    width: 1000px;

    & .nav {
        background-image: none;
    }
}
