.topNews {
    padding: 20px 0;
    font-family: '微软雅黑';
    border-bottom: 1px dotted #ccc;
}

.first_title {
    font-size: 22px;
    & a {
        color: #222;
        &:hover {
            text-decoration: none;
        }
    }
}

.second_title {
    margin-top: 18px;
    font-size: 16px;
    font-weight: normal;
    & a {
        color: #404040;
    }
}

.first_title,
.second_title {
    & a:hover {
        color: #ff3040; /* 变量 */
    }
}

.children {
    margin-left: 10px;
}
