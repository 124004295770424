.top_ad {
    margin: 0 auto 28px;
    width: 1000px;
}
.main {
    margin: 0 auto;
    width: 1000px;
}
.passAd {
    margin: 30px auto 0;
    width: 1000px;
    height: 90px;
}
.footer_ad {
    margin: 28px auto 0;
    width: 1000px;
}
