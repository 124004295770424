.go_to_top {
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: 540px;
    width: 50px;
    height: 50px;
    background-color: #ededed;
    background-image: url('./top.png');
    background-repeat: no-repeat;
    background-position: center center;
    outline: none;
    border: none;
    &:hover {
        background-color: #999;
        background-image: url('./topHover.png');
    }
}
@-webkit-keyframes opacity0to1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes opacity0to1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes opacity0to1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes opacity0to1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes opacity1to0 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@-moz-keyframes opacity1to0 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@-o-keyframes opacity1to0 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes opacity1to0 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.opacity_0,
.opacity_0_to_1 {
    opacity: 0;
}
.opacity_1,
.opacity_1_to_0 {
    opacity: 1;
}
.opacity_1 {
    cursor: pointer;
}
.opacity_0_to_1,
.opacity_1_to_0 {
    -webkit-animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-duration: 0.3s;
    -moz-animation-fill-mode: forwards;
    -o-animation-duration: 0.3s;
    -o-animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
.opacity_0_to_1 {
    cursor: pointer;
    -webkit-animation-name: opacity0to1;
    -moz-animation-name: opacity0to1;
    -o-animation-name: opacity0to1;
    animation-name: opacity0to1;
}
.opacity_1_to_0 {
    -webkit-animation-name: opacity1to0;
    -moz-animation-name: opacity1to0;
    -o-animation-name: opacity1to0;
    animation-name: opacity1to0;
}
