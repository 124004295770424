.pindaodushu {
    padding: 0 10px;
    & li {
        float: left;
        margin-bottom: 20px;
        width: 124px;
        border: 1px solid #e1e1e3;
    }
}
.imglink {
    display: block;
    overflow: hidden;
    width: 124px;
    height: 167px;
    & img {
        width: 100%;
    }
}
.link {
    padding: 0 10px;
    height: 36px;
    font-size: 12px;
    line-height: 36px;
    overflow: hidden;
    text-align: center;
    & a {
        color: #2b2b2b;
        &:hover {
            color: #4f788a;
        }
    }
}
.ml {
    margin-left: 28px;
}
