.footer {
    width: 1000px;
    margin: 0 auto;
    border-top: 1px solid #ccc;
}

.foot_link {
    position: relative;
    height: 28px;
    padding-top: 15px;
    border-top: 1px solid #fff;
    overflow: hidden;
    color: #000;
    line-height: 28px;
    font-size: 12px;
}

.list {
    text-align: center;
}

.link {
    color: #000 !important;
    font-size: 12px;
    text-decoration: none;
    padding: 0 7px 0 14px;
    border-right: 1px solid #000;
}

.link:hover {
    color: #f54343 !important;
    text-decoration: underline;
}

.link:first-child {
    padding-left: 0;
}

.link:last-child {
    border: 0;
}

.copyright {
    font-family: Arial, '宋体';
    color: #666;
    height: 28px;
    text-align: center;
    overflow: hidden;
    line-height: 28px;
    & span {
        padding-right: 12px;
        font-size: 12px;
    }
}
