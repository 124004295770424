/* 通用样式 */
.news_group {
    position: relative;
    margin-bottom: 25px;
}
.news_group_title {
    position: relative;
    margin-bottom: 10px;
    padding: 12px 10px 10px 4px;
    background-color: #f4f4f6;
}
.block {
    display: inline-block;
    *float: left;
    margin-right: 10px;
    height: 20px;
    width: 5px;
    *vertical-align: 3px;
    background-color: #ff2f42; /* 变量 */
}
.link_title {
    *float: left;
    vertical-align: 3px;
    font: 16px '微软雅黑';
    font-weight: bold;
    color: #222;
}
.more {
    float: right;
    position: relative;
    top: 3px;
    font-size: 14px;
    color: #000;
}
